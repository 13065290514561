<template>
  <v-container
    style="height: 100%;"
    class="pa-0"
  >
    <v-card
      height="100%"
    >
      <v-toolbar
        flat
        width="100%"
        class="toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$emit('close')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title
          style="white-space: normal;"
          class="mt-4"
        >
          {{ $t('actions|select_new_required_document') }}
        </v-toolbar-title>
      </v-toolbar>

      <v-list
        class="pb-10"
      >
        <v-list-item-group
          v-model="activeReqDocuments"
          multiple
          dense
        >
          <template v-for="(item, i) in availableRequestedDocuments">
            <v-divider
              v-if="i !== 0 && item.name"
              :key="`divider-${i}`"
            />

            <v-list-item
              v-if="item.name"
              :key="`item-${i}`"
              :value="item"
              active-class="deep-purple--text accent-4"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    mdi-file-hidden
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    style="white-space: normal;"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="deep-purple accent-4"
                  />
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <div
        class="bottom-toolbar-element pa-2 d-flex justify-center"
      >
        <v-btn
          rounded
          outlined
          dense
          small
          color="primary"
          min-width="150"
          :disabled="!activeReqDocuments.length"
          @click="addFields()"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </div>
    </v-card>
    <v-toolbar
      flat
      color="primary"
      class="bottom-element-add-req-docs-to-workbench"
    >
      <v-text-field
        v-model="searchText"
        dense
        dark
        clearable
        class="mt-4 mr-2"
        prepend-inner-icon="mdi-magnify"
        color="white"
        @input="$emit('searching-field', searchText)"
      />
      <v-menu
        offset-y
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            :attrs="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-earth
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(lang, index) in settings.activeLanguages"
            :key="index"
            @click="$emit('languageChange', lang)"
          >
            <v-list-item-title>{{ lang }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { mdiCog } from '@mdi/js'

export default {
  props: {
    availableRequestedDocuments: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      customField: false,
      customFieldInput: null,
      activeReqDocuments: [],
      selectedLanguage: null,
      searchText: '',
      icons: {
        cog: mdiCog
      }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings
    })
  },
  methods: {
    addFields () {
      this.$emit('addFieldsToWorkbench', this.activeReqDocuments)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar {
    position: sticky;
    top: 48px;
    min-height: 80px;
    z-index: 1;
  }
  .toolbar-extention {
    position: sticky;
    top: 128px;
    z-index: 1;
    background-color: #F6F4F4;
    padding-bottom: 10px;
  }
  .bottom-toolbar-element {
    position: fixed;
    bottom: 56px;
    width: 100%;
    z-index: 1;
    background-color: #F6F4F4;
  }
  .bottom-element-add-req-docs-to-workbench {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
